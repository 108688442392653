/* You can add global styles to this file, and also import other style files */
:root {
    // Dimensions
    --headerHeight: 35px;
    --defaultMargin: 20px;
    --headerAdjustedHeight: calc(var(--headerHeight) + var(--defaultMargin)); // 35px + 60px = 95px;

    // Colors
    --black: #333;
    --white: #fff;
    --gray-dark: #4d4d4d;
    --gray-medium: #ccc;
    --gray-light: #eee;
    --gray-shadow: #ddd;

    --red: #e51e2e;
    --gold: #fdb71c;
    --blue: #8bd4e8;
    --navy: #0d233f;
}

// Global Reset
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    user-select: none;
}

body {
    font-family: Metronic Pro, Arial, Helvetica, sans-serif;
    background-color: var(--gray-light);
    height: 100vh;

}

:root {
    --tireWallDefaultMargin: 20px;
    --globalBorderRadius: 10px;
    --globalBoxShadow: 3px 3px 5px 1px var(--gray-medium);
}

.semibold {
    font-weight: 600;
}

a {
    text-decoration: none;
}

.page-container {
    overflow-y: auto;
    padding: calc(2 * var(--tireWallDefaultMargin)) var(--tireWallDefaultMargin);
    z-index: 10;
    position: relative;

    .buttons {
        display: flex;

        &>* {
            margin-right: calc(var(--tireWallDefaultMargin) / 2);
        }
    }
}

.flex-row-break {
    flex-basis: 100%;
    height: 0;
}

// Overrideing Tire Pros common component styles
.background--top {
    z-index: 2 !important;
}

.header {
    background-color: var(--white);
    z-index: 1000;

    .header__time {
        margin-right: 150px;
    }
}